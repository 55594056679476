import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from '../components/Layout'

import KeymakerWhiteSvg from '../assets/svg/km-white.svg';
import TriangleSvg from '../assets/svg/triangle.svg';
import arrowSvg from '../assets/images/arrow.svg';
import KeymakerLogoTypeSvg from '../assets/svg/km-logo-type-m.svg'

import { pageLink } from '../utils'


import './index.scss'

// markup
const IndexPage = () => {
  return (
    <Layout
      title="Keymaker | Home"
      pageClassName="page-home"
    >
      <section className="page-home__hero">
        <div className="page-home__hero__container">
          <h1>Keymaker</h1>
          <KeymakerWhiteSvg className="logo-text"/>
          <p>Unlock an open and connected Metaverse</p>
          <Link className="button" to={ pageLink.developers }>START BUILDING</Link>
          <Link className="button" to={ pageLink.whitepaper }>READ WHITEPAPER</Link>
        </div>
      </section>
      <section className="page-home__search-engine">
        <StaticImage 
          className="dark-portol-mobile"
          src="../assets/images/portal_illustration.png"
          placeholder="none"
          alt=""
        />
        <div className="page-home__search-engine__container">
          <div className="page-home__search-engine__container__left">
            <StaticImage 
              className="dark-portol-desktop"
              src="../assets/images/portal_illustration.png"
              placeholder="none"
              alt=""
            />
          </div>
          <div className="page-home__search-engine__container__right">
            <KeymakerLogoTypeSvg className="logo-type"/>
            <h1>The World’s First <span className="highlight">Search Engine</span> for the Metaverse</h1>
            <p>Keymaker is the leading search engine and teleport protocol that unlocks a truly open, accessible and connected Metaverse. </p>
          </div>
        </div>
      </section>
      <section className="page-home__portocol">
        <div className="page-home__portocol__container">
          <div className="page-home__portocol__container__left">
            <h1>1st Open Teleportation Protocol</h1>
            <p>Search for what you want and get there. Live, work and play in the Metaverse.</p>
            <Link className="button" to="/">WATCH DEMO</Link>
          </div>
        </div>
      </section>
      <section className="page-home__vision">
        <div className="page-home__vision__row">
          <div className="page-home__vision__row__cell heading">
            <div className="inner">
              Freedom of Movement for Everyone, Everywhere
            </div>
          </div>
          <div className="page-home__vision__row__cell heading">
            <div className="inner">
              A Connected and Seamless Experience
            </div>
          </div>
          <div className="page-home__vision__row__cell heading">
            <div className="inner">
              Break Down Walled Gardens
            </div>
          </div>
        </div>
        <div className="arrow">
          <div className="arrow__line">
            <TriangleSvg className="triangle" />
          </div>
          {/* <img className="arrow" src={arrowSvg} alt="arrow"/> */}
        </div>
        <div className="page-home__vision__row">
          <div className="page-home__vision__row__cell body-copy">
            
            Navigate the Metaverse using the power of voice
          </div>
          <div className="page-home__vision__row__cell  body-copy">
            Travel between virtual worlds
          </div>
          <div className="page-home__vision__row__cell  body-copy">
            We believe the future should be co-created and accessible to all
          </div>
        </div>
        <div className="page-home__vision__mobile">
          <div className="page-home__vision__mobile__element freedom">
            <div className="page-home__vision__mobile__element__heading">
              Freedom of Movement for Everyone, Everywhere
            </div>
            <div className="page-home__vision__mobile__element__body body-copy">
              Navigate the Metaverse using the power of voice
            </div>
          </div>
          <div className="page-home__vision__mobile__element connected">
            <div className="page-home__vision__mobile__element__heading">
              A Connected and Seamless Experience
            </div>
            <div className="page-home__vision__mobile__element__body body-copy">
              Travel between virtual worlds
            </div>
          </div>
          <div className="page-home__vision__mobile__element garden">
            <div className="page-home__vision__mobile__element__heading">
              Break Down Walled Gardens
            </div>
            <div className="page-home__vision__mobile__element__body body-copy">
              We believe the future should be co-created and accessible to all
            </div>
          </div>
        </div>
      </section>  
      <section className="page-home__cta">
        <div className="page-home__cta__container">
          <div className="page-home__cta__container__element">
            <div className="page-home__cta__container__element__text">
              <h2 className="cta-heading">Keymaker Community</h2>
              <p className="cta-body">Get the latest news and updates via our channels including Discord, Telegram, Twitter and more.</p>
            </div>
            <Link className="button" to={ pageLink.community}>GET CONNECTED</Link>
          </div>
          <div className="page-home__cta__container__element">
            <div className="page-home__cta__container__element__text">
              <h2 className="cta-heading">Developer Resources</h2>
              <p className="cta-body">Documentation, protocol specification, demos, tutorials and more to get you up to speed.</p>
            </div>
            <Link className="button" to={ pageLink.developers }>START BUILDING</Link>
          </div>
          <div className="page-home__cta__container__element">
            <div className="page-home__cta__container__element__text">
              <h2 className="cta-heading">About Keymaker</h2>
              <p className="cta-body">Get to know more about us through our mission, vision and values.</p>
            </div>
            <Link className="button" to={ pageLink.about }>LEARN MORE</Link>
          </div>
        </div>   
      </section>      
    </Layout>
  )
}

export default IndexPage
